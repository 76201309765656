
<template>
  <div>
    <v-card>
      <v-card-subtitle>Lista </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="filtro.valor"
              label="numero/factura/control/documento"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <SelectAlmacen
              v-model="filtro.almacen"
              :add="false"
              :dense="true"
              :outlined="true"
              :clearable="true" 
              :filtro="{ id_puesto: store.state.puestoSelect.id_puesto} "
            />
          </v-col>
          <v-col cols="12" md="4">
            <BuscarProveedor :label="`Proveedor`" :add="false" class="mb-4" @GetProveedor="GetdatosProveedor" />
          </v-col>

          <v-col cols="12" md="3">
            <BuscarEmpleado @Getdatos="GetdatosEmpleado" :label="`Empleado`" />
          </v-col>
          <v-col cols="12" md="3">
            <SelectEstado
              v-model="filtro.estado"
              :dense="true"
              :outlined="true"
              :add="false"
              :padre="25"
              :clearable="true"
            />
          </v-col>
          <v-col cols="12" md="3">
            <DatePicker
              :clearable="true"
              v-model="filtro.fecha_vencimiento"
              :dense="true"
              :outlined="true"
              :label="'Fecha Vencimiento'"
            />
          </v-col>
          <v-col cols="12" md="3">
            <DatePicker
              :clearable="true"
              v-model="filtro.fecha_creacion"
              :dense="true"
              :outlined="true"
              :label="'Fecha Creacion'"
            />
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn small @click="cargar()" :loading="cargandoDatos" class="primary"> Buscar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">ID</th>
                <th class="text-left">Numeros</th>
                <th class="text-left">Fechas</th>
                <th class="text-left">Proveedor</th>
                <th class="text-left">Puesto</th>
                <th class="text-left">Empleado</th>
                <th class="text-left">Total</th>
                <th class="text-left">Accion</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in Lista" :key="index">
                <td class="text-center">
                  {{ item.id }} <br />
                  <v-chip x-small :color="colorEstatus(item.estado_json.id)">{{ item.estado_json.descripcion }}</v-chip> <br> 
                </td>
                <td>
                  <small>Documento: {{ item.numero_documento }}</small> <br />
               
                </td>
                <td>
                  <small>Vencimiento: {{ funciones.formatoFecha(item.fecha_vencimiento) }}</small> <br />
                  <small>Creacion: {{ funciones.formatoFecha(item.fecha, 3) }}</small>
                </td>
                <td>
                  <small> {{ item.proveedor.nombre }} </small> <br />
                  <small> {{ item.proveedor.documento }} </small>
                </td>
                <td>
                  <small> {{ item.puesto.nombre }}</small> <br />
                  <small>{{ item.almacen.descripcion }}</small>
                </td>
                <td>
                  <small> <v-icon small color="info"> {{ icons.mdiContentSave }}</v-icon> {{ item.empleado_json.nombre }} {{ item.empleado_json.apellido }}</small>
                <br>
                <small v-if="item.empleado_finaliza_json.nombre"><v-icon small color="success"> {{ icons.mdiAccountCheck }}</v-icon> {{ item.empleado_finaliza_json.nombre }} {{ item.empleado_valida_json.apellido }}</small>
                <br>
                <small v-if="item.empleado_anula_json.nombre" > <v-icon small color="error"> {{ icons.mdiCancel }}</v-icon>  {{ item.empleado_anula_json.nombre }} {{ item.empleado_anula_json.apellido }}</small>
                </td>
                <td>
                  <small>${{ item.total }} </small>
                </td>

                <td>
                    <BtnPdfCompra  :id="item.id" :tipoBtn="2"  />
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                    

                      <v-btn small   icon v-bind="attrs" v-on="on"  @click="GetVer(item)">
                            <v-icon   color="primary">{{ icons.mdiEye }}</v-icon>
                        </v-btn> 
                    </template>
                    <span>Ver</span>
                  </v-tooltip>
                  <br>
                  <v-tooltip bottom v-if="validarAcciones(acciones.INVENTARIO_COMPRA_MODIFICAR) && item.estado == 89" >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small :disabled="item.estado == 35" icon v-bind="attrs" v-on="on"  @click="GetEditar(item)">
                            <v-icon   color="info">{{ icons.mdiPencil }}</v-icon>
                        </v-btn> 
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="validarAcciones(acciones.INVENTARIO_COMPRA_ANULAR) && item.estado == 89" >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small :disabled="item.estado == 35" icon v-bind="attrs" v-on="on"  @click="GetAnular(item)">
                            <v-icon   color="error">{{ icons.mdiDeleteOutline }}</v-icon>
                        </v-btn>
                      
                    </template>
                    <span>Anular</span>
                  </v-tooltip>

                  <v-tooltip bottom v-if="validarAcciones(acciones.INVENTARIO_COMPRA_VALIDAR) && item.estado == 89 " >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small :disabled="item.estado == 34" icon v-bind="attrs" v-on="on"  @click="GetValidar(item)">
                            <v-icon   color="success">{{ icons.mdiAccountCheck }}</v-icon>
                        </v-btn>
                      
                    </template>
                    <span>Recibir/Facturar</span>
                  </v-tooltip>
                  
                </td>
              </tr>
            </tbody>
            <tbody></tbody>
          </template>
        </v-simple-table>
        <div class="text-right">
          <span class="pt-4 mr-2">Total Registros: {{ TotalRow }}</span>
          <br />
          <v-pagination :disabled="cargandoDatos" v-model="Pagina" :length="Paginas" :total-visible="7"></v-pagination>
        </div>
      </v-card-text>
      <ModalViewCompra  @GetEditar="GetEditar" @GetAnular="GetAnular" @GetValidar="GetValidar" ref="ModalViewCompraRef"></ModalViewCompra>
    </v-card>
  </div>
</template>
  
<script>
import CompraServices from '@/api/servicios/inventario/CompraServices'
import { onBeforeMount, ref, context, watch } from '@vue/composition-api'
import BuscarProveedor from '../../proveedor/componentes/BuscarProveedor.vue'
import SelectAlmacen from '@/views/sistema/inventario/maestro/almacen/componentes/Select.vue'
import BuscarEmpleado from '@/views/sistema/administracion/archivo/empleado/components/BuscarEmpleado.vue'
import SelectEstado from '@/views/sistema/administracion/archivo/estado/componentes/Select.vue'
import DatePicker from '@/components/DatePicker.vue'
import ModalViewCompra from './ModalViewCompra.vue'
import BtnPdfCompra from './BtnPdfCompra.vue'
import { acciones } from '@/modulos' 
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiStoreEdit,
  mdiPencilOutline,
  mdiAccountCancel,
  mdiAccountEdit,
  mdiAccountCheck,
  mdiCheck,
  mdiCancel,
  mdiStoreEditOutline,
  mdiPencil,
  mdiCached,
  mdiEye,
  mdiContentSave
} from '@mdi/js'
import config from '@/api/config'
import store from '@/store'
import funciones from '@/funciones/funciones'
import Vue from 'vue'
export default {
  components: {
    SelectAlmacen,
    BuscarEmpleado,
    SelectEstado,
    DatePicker,
    BuscarProveedor,
    ModalViewCompra,
    BtnPdfCompra
  },
  watch:{
    '$store.state.puestoSelect.id_puesto': function(news,olds){
    
        if (news != olds){ 
            this.cargarInicio()
        }
    }
  },
  setup(props, context) {
    const search = ref('')
    const Lista = ref([])
    const ListaLoad = ref(false)
    const cargandoDatos = ref(false)
    const Pagina = ref(1)
    const Limit = ref(5)
    const Paginas = ref(1)
    const TotalRow = ref(1)
    const ModalViewCompraRef = ref(null)
    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)

      return valor
    }
    const filtroInit = {
      id_empleado: null,
      id_almacen: null,
      estado: null,
      id_puesto: null,
      fecha_vencimiento: null,
      fecha_creacion: null,
      id_proveedor: null,
      valor: '',
    }
    const anulando = ref(false)
    const colorEstatus = item => {
      switch (item) {
        case 89:
          return 'info'
          break
        case 90:
          return 'error'
          break
        case 91:
          return 'error'
          break
        case 92:
          return 'success'
          break
        default:
          return 'default'
          break
      }
    }
    const colorEstatusPago = item => {
      switch (item) {
        case 64:
          return 'warning'
          break
        case 65:
          return 'success'
          break
        case 63:
          return 'error'
          break
        default:
          return 'default'
          break
      }
    }
    const GetdatosProveedor = datos => {
      if (datos) {
        filtro.value.id_proveedor = datos.id
      } else {
        filtro.value.id_proveedor = null
      }
    }


    const GetValidar = item => {
      if (item.estado != 35) {
        anulando.value = true
        Vue.swal({
          icon: 'warning',
          html: '<h3>¿Seguro que quiere validar la compra? </h3>' + `<p>${item.numero_documento ?? item.id}</p>
          <p> La compra quedara cerrada y no podra modificar. </p>
          `,
          showCancelButton: true,
          confirmButtonText: 'Si, Seguro',
          showLoaderOnConfirm: true,
          cancelButtonText: 'No',
          preConfirm: () => {
            return CompraServices.validarCompra({ id: item.id, id_empleado: store.state.user.id_empleado })
              .then(response => {
                if (response.data.mensaje == "FACTURA VALIDADA CON EXITO") {
                  Vue.swal({
                    icon: 'success',
                    title: `Exito`,
                    text: response.data.mensaje,
                  })
                  cargar()
                } else {
                  Vue.swal({
                    icon: 'error',
                    title: `Oops...`,
                    text: `${response.data.mensaje}`,
                  })
                }
                return true
              })
              .catch(error => {
                Vue.swal({
                  icon: 'error',
                  title: `Oops...`,
                  text: `hubo un error: ${error}`,
                })
                return false
              })
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then(result => {
          anulando.value = false
        })
      }
    }

    const GetAnular = item => {
      if (item.estado != 35) {
        anulando.value = true
        Vue.swal({
          icon: 'warning',
          html: '<h3>¿Seguro que quiere anular la compra? </h3>' + `<p>${item.numero_documento ?? item.id}</p>`,
          showCancelButton: true,
          confirmButtonText: 'Si, Seguro',
          showLoaderOnConfirm: true,
          cancelButtonText: 'No',
          preConfirm: () => {
            return CompraServices.anularOrdenCompra({ id: item.id, id_empleado: store.state.user.id_empleado })
              .then(response => {
                if (response.data.mensaje == "ANULADA CON EXITO") {
                  Vue.swal({
                    icon: 'success',
                    title: `Exito`,
                    text: response.data.mensaje,
                  })
                  cargar()
                } else {
                  Vue.swal({
                    icon: 'error',
                    title: `Oops...`,
                    text: `${response.data.mensaje}`,
                  })
                }
                return true
              })
              .catch(error => {
                Vue.swal({
                  icon: 'error',
                  title: `Oops...`,
                  text: `hubo un error: ${error}`,
                })
                return false
              })
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then(result => {
          anulando.value = false
        })
      }
    }
    const GetdatosEmpleado = datos => {
      if (datos) {
        filtro.value.id_empleado = datos.id
      } else {
        filtro.value.id_empleado = null
      }
    }
    const filtro = ref(JSON.parse(JSON.stringify(filtroInit)))
    const GetEditar = item => {
      console.log(item)
      context.emit('GetEditar', { ...item })
    }
    onBeforeMount(() => {
      cargar()
    })
    watch(Pagina, () => {
      cargar()
    })
    const GetVer = item => {
      ModalViewCompraRef.value.abrir(item.id)
    }
    const cargarInicio = () => {
      Pagina.value = 1
      Limit.value = 5
      cargar()
    }
    const cargar = () => {
      try {
        cargandoDatos.value = true
        CompraServices.productoOrdenCompraListarPaginado({
          ...filtro.value,
          limit: Limit.value,
          pagina: Pagina.value - 1,
          id_puesto : store.state.puestoSelect.id_puesto
        })
          .then(response => {
            console.log(response)
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              Lista.value = response.data.datos.datos
              Paginas.value = response.data.datos.paginado.paginas
              TotalRow.value = response.data.datos.paginado.registros
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargandoDatos.value = false
          })
      } catch (error) {
        cargandoDatos.value = false
      }
    }

    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountCancel,
        mdiAccountEdit,
        mdiAccountCheck,
        mdiCheck,
        mdiCancel,
        mdiStoreEdit,
        mdiStoreEditOutline,
        mdiPencil,
        mdiCached,
        mdiEye,
        mdiContentSave
      },
      search,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Numero', value: 'numero_documento' },
        { text: 'Fechas', value: 'fecha_vencimiento' },
        { text: 'Proveedor', value: 'proveedor.nombre' },
        { text: 'Empleado', value: 'empleado_json.nombre' },
        { text: 'Total', value: 'total' },
        { text: 'Estado', value: 'estado_json.descripcion' },
        { text: 'Accion', value: 'accion', sortable: false },
      ],
      Lista,
      ListaLoad,
      GetEditar,
      cargar,
      config,
      cargandoDatos,
      filtro,
      store,
      funciones,
      colorEstatus,
      Pagina,
      Limit,
      Paginas,
      TotalRow,
      cargarInicio,
      GetdatosEmpleado,
      GetdatosProveedor,
      ModalViewCompraRef,
      GetVer,
      GetAnular,
      anulando,
      validarAcciones,
      acciones,
      GetValidar, 
      colorEstatusPago
    }
  },
}
</script>
  
<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
  