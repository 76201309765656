<template>
  <v-card>
    <v-card-title>
      <span>Compra</span>
      <v-spacer></v-spacer>
      <v-btn v-if="modal == true" icon @click="cerrarModal()">
        <v-icon>{{ icons.mdiClose }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text v-if="cargando" class="text-center">
      <h4>Buscando la compra...</h4>
    </v-card-text>
    <v-card-text v-if="!cargando && datos">
      <v-row>
        <v-col lg="8" md="8" sm="12" cols="12">
          <CabeceraEmpresa></CabeceraEmpresa>
        </v-col>
        <v-col lg="4" md="4" sm="12" cols="12">
          <h2 class="pt-0 pb-2 text-center"># {{ datos.numero_documento }}</h2>
          <div class="text-center">
            Fecha Creacion <br />
            <h2 class="pt-0 pb-2 text-center">
              <small> {{ FuncionesGenerales.formatoFecha(datos.fecha, 1) }} </small>
            </h2>
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="mt-4 mb-2">
        <v-col md="12" cols="12">
          <span class="m-0 p-0"> <strong>Proveedor: </strong> {{ datos.proveedor.nombre }}</span>
          <br />
          <span class="m-0 p-0"> <strong>Rif:</strong> {{ datos.proveedor.rif }}</span>
          <br />
          <span class="m-0 p-0"> <strong>Telefono:</strong> {{ datos.proveedor.telefono }}</span>
          <br />
          <span class="m-0 p-0"> <strong>Direccion:</strong> {{ datos.proveedor.direccion }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="mt-4 mb-2">
        <v-col md="8" cols="12">  
          <span class="m-0 p-0"> <strong>Area: </strong> {{ datos.puesto.nombre }}</span>
          <br />
          <span class="m-0 p-0"> <strong>Amacen: </strong> {{ datos.almacen.descripcion }}</span>
          <br />
        
         
      
        </v-col>
        <v-col md="4" cols="12">
          <span class="my-0 py-0">
            <strong>Fecha Vencimiento: </strong> {{ FuncionesGenerales.formatoFecha(datos.fecha_vencimiento, 1) }}
          </span>
          <br />
          <span class="my-0 py-0"
            ><strong>Hora creacion: </strong> {{ FuncionesGenerales.formatoFecha(datos.fecha, 2) }}
          </span>
          <br />
          <span class="my-0 py-0"
            ><strong>Empleado: </strong> {{ datos.empleado_json.nombre }} {{ datos.empleado_json.apellido }}</span
          >
          <br />
          <span class="m-0 p-0"> <strong>Estado: </strong> {{ datos.estado_json.descripcion }}</span> 
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Id</th>
                  <th class="text-left">Producto</th>
                  <th class="text-left">Cantidad</th>
                  <th class="text-right">Costo Und</th>
                  <th class="text-right">Impuesto</th>
                  <th class="text-right">Descuento</th>
                  <th class="text-right">iva</th>
                  <th class="text-right">total</th> 
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in datos.productos" :key="i">
                  <td>{{ item.id_producto }}</td>
                  <td class="text-right">{{ item.producto.nombre }}</td>
                  <td class="text-right">{{ item.cantidad }}</td>
                  <td class="text-right">{{ monedaSimbolo() }}.{{ FuncionesGenerales.formatoNumeric(monedaValor(item.costo_unidad)) }}</td>
                  <td class="text-right">{{ monedaSimbolo() }}.{{ FuncionesGenerales.formatoNumeric(monedaValor(item.impuesto)) }}</td>
                  <td class="text-right">{{ monedaSimbolo() }}.{{ FuncionesGenerales.formatoNumeric(monedaValor(item.descuento)) }}</td>
                  <td class="text-right">{{ monedaSimbolo() }}.{{ FuncionesGenerales.formatoNumeric(monedaValor(item.iva)) }}</td>
                  <td class="text-right">{{ monedaSimbolo() }}.{{ FuncionesGenerales.formatoNumeric(monedaValor(item.costo_total)) }}</td> 
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <br />
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="7">
              <v-list width="100%">
                <strong class="m-0 p-0"> Pagos: </strong> <br />
                <v-divider></v-divider> 
                <template v-for="(item, index) in datos.pagos">
                  <v-list-item :key="index">
                    <v-list-item-avatar size="30">
                      <v-avatar
                        :color="item.cuenta_bancaria ? '' : 'primary'"
                        :class="item.cuenta_bancaria ? '' : 'v-avatar-light-bg white--text'"
                        size="32"
                      >
                        <v-img
                          v-if="item.cuenta_bancaria"
                          :src="config.api_imagenes + '/banco/img/' + item.cuenta_bancaria.banco.img_icon"
                        >
                        </v-img>
                        <span v-else> {{ item.moneda.simbolo }}</span>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content class="p-0 m-0">
                      <v-list-item-subtitle v-if="indFecha" class="py-0 my-0">
                        <strong
                          ><small>{{ item.fecha }}</small></strong
                        >
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="py-0 my-0">{{ item.nombre }} </v-list-item-subtitle>

                      <v-list-item-subtitle class="py-0 my-0"
                        >{{ item.moneda.tipo_pago.find(i => i.id == item.tipo_pago).nombre }} {{ item.moneda.simbolo }}:
                        {{ item.monto_moneda }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="py-0 my-0" v-if="item.moneda.tasa != 1">
                        <small
                          >Monto {{ datos.moneda.simbolo }}: {{ item.monto_real }}/ tasa: {{ item.moneda.tasa }}</small
                        >
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="py-0 my-0" v-if="item.cuenta_bancaria && item.cuenta_bancaria.numero_cuenta">
                        <small
                          >{{ item.cuenta_bancaria.banco.nombre }} | {{ item.cuenta_bancaria.numero_cuenta }}</small
                        >
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="py-0 my-0" v-if="item.referencia != ''"
                        ><small> Ref: {{ item.referencia }}</small>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider :key="index"></v-divider>
                </template>
              </v-list>
              <strong class="m-0 p-0"> Observacion: </strong> <br />
              <span>{{ datos.observacion }}</span>
            </v-col>
            <v-col cols="5">
              <v-simple-table dense>
                <tbody>
                  <tr>
                    <td colspan="2"><strong>Totales</strong></td>
                  </tr>
                  <tr>
                    <td>Sub Total</td>
                    <td class="text-right">
                      {{ monedaSimbolo() }} {{ FuncionesGenerales.formatoNumeric(monedaValor(datos.subtotal)) }}
                    </td>
                  </tr>
                  <tr>
                    <td>Impuesto</td>
                    <td class="text-right">
                      {{ monedaSimbolo() }} {{ FuncionesGenerales.formatoNumeric(monedaValor(datos.impuesto)) }}
                    </td>
                  </tr>
                  <tr>
                    <td>IVA</td>
                    <td class="text-right">
                      {{ monedaSimbolo() }} {{ FuncionesGenerales.formatoNumeric(monedaValor(datos.iva)) }}
                    </td>
                  </tr>
                  <tr>
                    <td>Descuento</td>
                    <td class="text-right">
                      {{ monedaSimbolo() }} {{ FuncionesGenerales.formatoNumeric(monedaValor(datos.descuento)) }}
                    </td>
                  </tr>
                  <tr>
                    <td><h3>Total</h3></td>
                    <td class="text-right">
                      <h3>{{ monedaSimbolo() }} {{ FuncionesGenerales.formatoNumeric(monedaValor(datos.total)) }}</h3>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col cols="12" class="text-center mt-5">
          <BtnPdfCompra :id="id" :tipoBtn="1"></BtnPdfCompra>
          <v-btn
            v-if="VerButton == true && validarAcciones(acciones.INVENTARIO_COMPRA_EDITAR) && datos.estado != 34"
            color="info"
            class="ml-2"
            small
            :disabled="datos.estado != 89"
            @click="GetEditar(datos)"
          >
            <v-icon left small>
              {{ icons.mdiPencil }}
            </v-icon>
            Editar
          </v-btn>
          <v-btn
            v-if="VerButton == true && validarAcciones(acciones.INVENTARIO_COMPRA_ANULAR) && datos.estado != 34"
            color="error"
            class="ml-2"
            small
            :disabled="datos.estado != 89"
            @click="GetAnular(datos)"
          >
            <v-icon left small> {{ icons.mdiBlockHelper }} </v-icon>Anular
          </v-btn>

          <v-btn
            v-if="VerButton == true && validarAcciones(acciones.INVENTARIO_COMPRA_VALIDAR) && datos.estado != 34"
            color="success"
            class="ml-2"
            small
            :disabled="datos.estado != 89"
            @click="GetValidar(datos)"
          >
            <v-icon left small> {{ icons.mdiAccountCheck }} </v-icon>Recibir/Facturar
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<script>
import CabeceraEmpresa from '@/components/CabeceraEmpresa.vue'
import FuncionesGenerales from '@/funciones/funciones'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import CompraServices from '@/api/servicios/inventario/CompraServices'
import config from '@/api/config'
import BtnPdfCompra from './BtnPdfCompra.vue'
import store from '@/store'
import { acciones } from '@/modulos'
import { mdiClose, mdiPrinter, mdiPencil, mdiCheck, mdiBlockHelper, mdiFilePdfBox, mdiAccountCheck } from '@mdi/js'
export default {
  components: {
    CabeceraEmpresa,
    BtnPdfCompra,
  },
  props: {
    id: Number,
    modal: Boolean,
    VerButton: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const cargando = ref(false)
    const datos = ref({})
    const verReal = ref(false)
    onBeforeMount(() => {
      cargar()
    })
    watch(props, () => {
      cargar()
    })
    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)

      return valor
    }
    const cerrarModal = () => {
      context.emit('GetCerrarModal')
    }
    const cargar = () => {
      cargando.value = true
      CompraServices.productoOrdenCompraListarPaginado({ id: props.id })
        .then(response => {
          if ((response.data.mensaje = 'BUSQUEDA_EXITOSA')) {
            if (response.data.datos.datos != null && response.data.datos.datos.length > 0) {
              datos.value = response.data.datos.datos[0]
            } else {
              store.commit('setAlert', {
                message: 'No hay resultado en la busqueda',
                type: 'warning',
              })
            }
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'warning',
            })
          }
        })
        .catch(err => {
          store.commit('setAlert', {
            message: err,
            type: 'error',
                  error: {
                        ...err, 
                        response: error?.response?.data
                    },
                  funcion: 'cargar',
          })
        })
        .finally(() => {
          cargando.value = false
        })
    }
    const GetValidar = item => {
      context.emit('GetValidar', item)
    }
    const GetAnular = item => {
      context.emit('GetAnular', item)
    }
    const GetEditar = item => {
      context.emit('GetEditar', item)
    }
    const GetPdf = item => {
      context.emit('GetPdf', item)
    }
    const GetFinalizar = item => {
      context.emit('GetFinalizar', item)
    }
    const monedaSimbolo = () => {
      let simbolo = '$'
      /*if (datos.value.moneda && !verReal.value) {
        simbolo = datos.value.moneda.simbolo
      }*/
      return simbolo
    }
    const monedaValor = _dato => {
      let valor = _dato
      /*if (datos.value.moneda && !verReal.value) {
        valor = valor * datos.value.tasa
      }*/
      return valor
    }
    const monedaValorReal = _dato => {
      let valor = _dato
     /* if (datos.value.moneda) {
        valor = valor / datos.value.tasa
      }*/
      return valor
    }
    return {
      datos,
      FuncionesGenerales,
      cerrarModal,
      cargar,
      icons: {
        mdiClose,
        mdiPrinter,
        mdiPencil,
        mdiCheck,
        mdiBlockHelper,
        mdiFilePdfBox,
        mdiAccountCheck,
      },
      GetAnular,
      GetEditar,
      GetPdf,
      GetFinalizar,
      validarAcciones,
      GetValidar,
      acciones,
      monedaSimbolo,
      monedaValor,
      monedaValorReal,
      verReal,
      config
    }
  },
}
</script>