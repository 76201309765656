<template>
  <v-card flat class="pa-3 mt-2">
    <v-card-subtitle> Orden de Compra </v-card-subtitle>
    <v-form ref="form" lazy-validation>
      <v-card-text class="d-flex">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" lg="6">
                <BuscarProveedor
                  ref="BuscarProveedorRef"
                  :label="`Proveedor`"
                  class="mb-4"
                  @GetProveedor="GetProveedor"
                />
                <DatePicker v-model="datos.fecha_vencimiento" :dense="true" :outlined="true" :label="'Fecha Vencimiento'" />
              </v-col>
              <v-col cols="12" lg="6">
                <SelectAlmacen
                  :dense="true"
                  :outlined="true"
                  :add="false"
                  v-model="datos.almacen"
                  :filtro="{ id_puesto: store.state.puestoSelect.id_puesto }"
                />

                <v-textarea
                  v-model="datos.observacion"
                  rows="4"
                  class="mt-4"
                  label="Observaciones"
                  dense
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
    <v-divider></v-divider>
    <v-card-subtitle> Detalle </v-card-subtitle>
    <v-divider></v-divider>

    <v-card-text>
      <v-form ref="formDetalle" lazy-validation>
        <v-row>
          <v-col>
            <BuscarProducto
              :rules="reglas.requerido"
              :label="`Producto detalle`"
              @GetProducto="GetProductoDetalle"
              ref="RefProductoDetalle"
              :filtros="{ ind_compra: true }"
            />
            <small v-if="itemAdd.producto && itemAdd.producto.ult_compra"
              >{{ itemAdd.producto.ult_compra.nombre }}/ {{ itemAdd.producto.ult_compra.costo_unidad }}
              {{ monedaSimbolo() }}</small
            >
            <ViewInfoCompraProducto v-if="false" />
          </v-col>
         
        </v-row>
        <v-row class="mb-4 mt-2">
          <v-col cols="4" sm="3" md="2">
            <v-text-field
              v-on:keyup.enter="agregarDetalle()"
              @keyup="changeCalcular('cantidad')"
              v-model="itemAdd.cantidad"
              dense
              outlined
              :suffix="itemAdd.producto.id ? itemAdd.producto.unidad_medida.unidad : ''"
              label="Cantidad"
              :rules="reglas.numerico_requerido"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="4" sm="3" md="2">
            <v-text-field
              v-on:keyup.enter="agregarDetalle()"
              v-model="itemAdd.costo_unidad"
              dense
              outlined
              label="Costo Unidad"
              @keyup="changeCalcular('costo')"
              :rules="reglas.numerico_requerido"
              :prefix="monedaSimbolo()"
              type="number"
            >
              <template
                v-slot:append-outer
                v-if="
                  itemAdd.costo_unidad > 0 &&
                  itemAdd.producto &&
                  itemAdd.producto.ult_compra &&
                  itemAdd.producto.ult_compra.costo_unidad > 0
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="itemAdd.producto.ult_compra.costo_unidad < monedaValorReal(itemAdd.costo_unidad)"
                      small
                      color="error"
                      v-bind="attrs"
                      v-on="on"
                      >{{ icons.mdiArrowUpThick }}
                    </v-icon>
                    <v-icon
                      v-else-if="itemAdd.producto.ult_compra.costo_unidad == monedaValorReal(itemAdd.costo_unidad)"
                      small
                      color="info"
                      v-bind="attrs"
                      v-on="on"
                      >{{ icons.mdiEqual }}
                    </v-icon>
                    <v-icon v-else small color="success" v-bind="attrs" v-on="on"
                      >{{ icons.mdiArrowDownThick }}
                    </v-icon>
                  </template>

                  <span v-if="itemAdd.producto.ult_compra.costo_unidad < monedaValorReal(itemAdd.costo_unidad)">
                    <p class="mb-1">
                      Aumento segun la ultima compra un
                      {{
                        (
                          (monedaValorReal(itemAdd.costo_unidad) / itemAdd.producto.ult_compra.costo_unidad - 1) *
                          100
                        ).toFixed(0)
                      }}%
                    </p>
                    <p class="mb-0">Ultimo costo: {{ itemAdd.producto.ult_compra.costo_unidad }}$</p>
                    <p class="mb-0">Ultimo Proveedor: {{ itemAdd.producto.ult_compra.nombre }}</p>
                  </span>
                  <span v-else-if="itemAdd.producto.ult_compra.costo_unidad == monedaValorReal(itemAdd.costo_unidad)">
                    <p class="mb-1">Se mantuvo igual a la compra anterior</p>
                    <p class="mb-0">Ultimo costo: {{ itemAdd.producto.ult_compra.costo_unidad }}$</p>
                    <p class="mb-0">Ultimo Proveedor: {{ itemAdd.producto.ult_compra.nombre }}</p>
                  </span>
                  <span v-else>
                    <p class="mb-1">
                      Bajo segun la ultima compra un
                      {{
                        (
                          (monedaValorReal(itemAdd.costo_unidad) / itemAdd.producto.ult_compra.costo_unidad - 1) *
                          100 *
                          -1
                        ).toFixed(0)
                      }}%
                    </p>
                    <p class="mb-0">Ultimo costo: {{ itemAdd.producto.ult_compra.costo_unidad }} $</p>
                    <p class="mb-0">Ultimo Proveedor: {{ itemAdd.producto.ult_compra.nombre }}</p>
                  </span>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="4" sm="3" md="2">
            <v-text-field
              v-on:keyup.enter="agregarDetalle()"
              @keyup="changeCalcular('impuesto')"
              :rules="reglas.numerico_requerido"
              v-model="itemAdd.impuesto"
              dense
              outlined
              :label="`Otro Impuesto`"
              :prefix="monedaSimbolo()"
              type="number"
            >
              <template v-slot:append-outer>
                <TooltipInfo
                  :mensaje="`Otros impuesto se toman en cuenta para el costo del producto. Este impuesto se calcula por unidad`"
                />
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="4" sm="3" md="2">
            <v-text-field
              v-on:keyup.enter="agregarDetalle()"
              @keyup="changeCalcular('descuento')"
              :rules="reglas.numerico_requerido"
              v-model="itemAdd.descuento"
              dense
              outlined
              label="Descuento"
              prefix="%"
              type="number"
              :suffix="`${monedaSimbolo()} ${
                itemAdd.descuento > 0
                  ? (
                      (itemAdd.descuento / 100) *
                      (itemAdd.cantidad * (Number(itemAdd.costo_unidad) + Number(itemAdd.impuesto)))
                    ).toFixed(2)
                  : 0
              }`"
            ></v-text-field>
          </v-col>

          <v-col cols="4" sm="3" md="2">
            <v-text-field
              v-on:keyup.enter="agregarDetalle()"
              @keyup="changeCalcular('iva')"
              :rules="reglas.numerico_requerido"
              v-model="itemAdd.iva"
              dense
              outlined
              label="Iva"
              type="number"
              prefix="%"
              :suffix="`${monedaSimbolo()}${
                itemAdd.iva > 0
                  ? (
                      (itemAdd.iva / 100) *
                      (itemAdd.cantidad * (Number(itemAdd.costo_unidad) + Number(itemAdd.impuesto)) -
                        (itemAdd.descuento / 100) *
                          (itemAdd.cantidad * (Number(itemAdd.costo_unidad) + Number(itemAdd.impuesto))))
                    ).toFixed(2)
                  : 0
              }`"
            ></v-text-field>
          </v-col>

          <v-col cols="4" sm="3" md="2">
            <v-text-field
              v-on:keyup.enter="agregarDetalle()"
              @keyup="changeCalcular('costo_total')"
              :rules="reglas.numerico_requerido"
              v-model="itemAdd.costo_total"
              dense
              outlined
              type="number"
              label="Total"
              :prefix="monedaSimbolo()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="12" class="text-right">
            <v-btn small class="primary" @click="agregarDetalle()">Agregar</v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Id</th>
              <th class="text-left">Producto</th>
              <th class="text-right">Cantidad</th>
              <th class="text-right">Costo Und</th>
              <th class="text-right">Otro Impuesto</th>
              <th class="text-right">Descuento</th>
              <th class="text-right">Iva</th>
              <th class="text-right">Total</th>
              <th class="text-left">-</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in datos.productos" :key="index">
              <td>
                {{ item.id }}
              </td>
              <td>
                <strong> {{ item.producto.nombre }} </strong> <br />
                <small v-if="item.producto.unidad_medida">{{ item.producto.unidad_medida.descripcion }}</small> <br />
                <small v-if="item.producto.cuenta_contable">
                  <strong>{{ item.producto.cuenta_contable.nombre }}</strong>
                </small>
              </td>
              <td class="text-right">
                {{ funciones.formatoNumeric(item.cantidad) }} {{ item.producto.unidad_medida.unidad }}
              </td>
              <td class="text-right">
                <v-tooltip
                  bottom
                  v-if="
                    item.costo_unidad > 0 &&
                    item.producto &&
                    item.producto.ult_compra &&
                    item.producto.ult_compra.costo_unidad > 0
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="item.producto.ult_compra.costo_unidad < monedaValor(item.costo_unidad)"
                      small
                      color="error"
                      v-bind="attrs"
                      v-on="on"
                      >{{ icons.mdiArrowUpThick }}
                    </v-icon>
                    <v-icon
                      v-else-if="item.producto.ult_compra.costo_unidad == monedaValor(item.costo_unidad)"
                      small
                      color="info"
                      v-bind="attrs"
                      v-on="on"
                      >{{ icons.mdiEqual }}
                    </v-icon>
                    <v-icon v-else small color="success" v-bind="attrs" v-on="on"
                      >{{ icons.mdiArrowDownThick }}
                    </v-icon>
                  </template>
                  <span v-if="item.producto.ult_compra.costo_unidad < monedaValor(item.costo_unidad)">
                    <p class="mb-1">
                      Aumento segun la ultima compra un
                      {{
                        ((monedaValor(item.costo_unidad) / item.producto.ult_compra.costo_unidad - 1) * 100).toFixed(0)
                      }}%
                    </p>
                    <p class="mb-0">Ultimo costo: {{ item.producto.ult_compra.costo_unidad }} $</p>
                    <p class="mb-0">Ultimo Proveedor: {{ item.producto.ult_compra.nombre }}</p>
                  </span>
                  <span v-else-if="item.producto.ult_compra.costo_unidad == monedaValor(item.costo_unidad)">
                    <p class="mb-1">
                      Se mantuvo igual a la compra anterior
                      {{
                        ((monedaValor(item.costo_unidad) / item.producto.ult_compra.costo_unidad - 1) * 100).toFixed(0)
                      }}%
                    </p>
                    <p class="mb-0">Ultimo costo: {{ item.producto.ult_compra.costo_unidad }}{{ monedaSimbolo() }}</p>
                    <p class="mb-0">Ultimo Proveedor: {{ item.producto.ult_compra.nombre }}{{ monedaSimbolo() }}</p>
                  </span>
                  <span v-else>
                    <p class="mb-1">
                      Bajo segun la ultima compra un
                      {{
                        (
                          (monedaValor(item.costo_unidad) / item.producto.ult_compra.costo_unidad - 1) *
                          100 *
                          -1
                        ).toFixed(0)
                      }}%
                    </p>
                    <p class="mb-0">Ultimo costo: {{ item.producto.ult_compra.costo_unidad }}$</p>
                    <p class="mb-0">Ultimo Proveedor: {{ item.producto.ult_compra.nombre }}$</p>
                  </span>
                </v-tooltip>

                {{ monedaSimbolo() }}.{{ funciones.formatoNumeric(monedaValor(item.costo_unidad)) }}
              </td>
              <td class="text-right">{{ monedaSimbolo() }}.{{ funciones.formatoNumeric(item.impuesto) }}</td>
              <td class="text-right">
                <small
                  >{{ monedaSimbolo() }}.{{
                    item.descuento > 0
                      ? funciones.formatoNumeric(
                          (item.descuento / 100) *
                            (item.cantidad * (Number(monedaValor(item.costo_unidad)) + Number(item.impuesto))),
                        )
                      : 0
                  }}</small
                >
                <br />
                %{{ funciones.formatoNumeric(item.descuento) }}
              </td>
              <td class="text-right">
                <small
                  >{{ monedaSimbolo() }}.{{
                    item.iva > 0
                      ? funciones.formatoNumeric(
                          (item.iva / 100) *
                            (item.cantidad *
                              (Number(monedaValor(item.costo_unidad)) + Number(monedaValor(item.impuesto))) -
                              (item.descuento / 100) *
                                (item.cantidad *
                                  (Number(monedaValor(item.costo_unidad)) + Number(monedaValor(item.impuesto))))),
                        )
                      : 0
                  }}</small
                >
                <br />
                %{{ funciones.formatoNumeric(item.iva) }}
              </td>
              <td class="text-right">
                {{ monedaSimbolo() }}.{{ funciones.formatoNumeric(monedaValor(item.costo_total)) }}
              </td>
             
              <td>
                <v-btn icon color="primary" small @click="editarProducto(item)">
                  <v-icon small>{{ icons.mdiPencil }}</v-icon>
                </v-btn>
                <v-btn icon color="error" small @click="eliminarProdcuto(item)">
                  <v-icon small>{{ icons.mdiDelete }}</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col cols="8">
          <div class="text-center">
            <v-btn color="secondary" class="me-3" @click="limpiar()" small> Limpiar </v-btn>
            <v-btn color="primary" class="me-3" small @click="Guardar()"> Guardar </v-btn>
          </div>
        </v-col>
        <v-col cols="4">
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td colspan="2"><strong>Totales</strong></td>
                </tr>
                <tr>
                  <td>Sub Total</td>
                  <td class="text-right">
                    {{ monedaSimbolo() }} {{ funciones.formatoNumeric(monedaValor(datos.sub_total)) }}
                  </td>
                </tr>
                <tr>
                  <td>Impuesto</td>
                  <td class="text-right">
                    {{ monedaSimbolo() }} {{ funciones.formatoNumeric(monedaValor(datos.impuesto)) }}
                  </td>
                </tr>
                <tr>
                  <td>IVA</td>
                  <td class="text-right">
                    {{ monedaSimbolo() }} {{ funciones.formatoNumeric(monedaValor(datos.iva)) }}
                  </td>
                </tr>
                <tr>
                  <td>Descuento</td>
                  <td class="text-right">
                    {{ monedaSimbolo() }} {{ funciones.formatoNumeric(monedaValor(datos.descuento)) }}
                  </td>
                </tr>

                <tr>
                  <td><h3>Total</h3></td>
                  <td class="text-right">
                    <h3>{{ monedaSimbolo() }} {{ funciones.formatoNumeric(monedaValor(datos.total)) }}</h3>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import BuscarProveedor from '../../proveedor/componentes/BuscarProveedor.vue'
import DatePicker from '@/components/DatePicker.vue'
import BuscarProducto from '@/views/sistema/inventario/producto/componentes/BuscarProducto.vue'
import SelectEstado from '@/views/sistema/administracion/archivo/estado/componentes/Select.vue'
import SelectAlmacen from '@/views/sistema/inventario/maestro/almacen/componentes/Select.vue'
import SelectAlmacenJson from '@/views/sistema/inventario/maestro/almacen/componentes/SelectJson.vue'
import funciones from '@/funciones/funciones'
import TooltipInfo from '@/components/TooltipInfo.vue'
import { mdiInformation, mdiDelete, mdiPencil, mdiArrowUpThick, mdiEqual, mdiArrowDownThick, mdiCached } from '@mdi/js'
import CompraServices from '@/api/servicios/inventario/CompraServices'
import ViewInfoCompraProducto from './ViewInfoCompraProducto.vue'
import MaestroService from '@/api/servicios/MaestroService'
import config from '@/api/config'
export default {
  watch: {
    '$store.state.puestoSelect.id_puesto': function () {
      this.limpiar()
    },
    'datos.moneda': function () {
      if (this.datos.moneda.tasa) {
        if (this.datos.id < 0) {
          this.datos.tasa = this.datos.moneda.tasa
        }
      }
    },
  },
  components: {
    BuscarProveedor,
    DatePicker,
    SelectEstado,
    SelectAlmacen,
    BuscarProducto,
    TooltipInfo,
    SelectAlmacenJson,
    ViewInfoCompraProducto,
  },
  setup(props, context) {
    const guardando = ref(false)
    const form = ref(null)
    const formDetalle = ref(null)
    const monedas = ref([])
    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)

      return valor
    }
    const RefProductoDetalle = ref(null)
    const MonedaPais = ref({})
    const reglas = {
      requerido: [v => !!v || 'Es requerido'],
      numerico_requerido: [
        v => !!v || 'Es requerido',
        v => !isNaN(v) || 'No es numero valido',
        v => !(v == Infinity) || 'No es numero valido 2',
      ],
    }
    const monedaSimbolo = () => {
      let simbolo = '$'
      if (datos.value.moneda) {
        simbolo = datos.value.moneda.simbolo
      }
      return simbolo
    }
    const monedaValor = _dato => {
      let valor = _dato
      if (datos.value.moneda) {
        valor = valor * datos.value.tasa
      }
      return valor
    }
    const monedaValorReal = _dato => {
      let valor = _dato
      if (datos.value.moneda) {
        valor = valor / datos.value.tasa
      }
      return valor
    }
   
    
    const datosInit = {
      id_empleado: null,
      id: -1,
      proveedor: null,
      numero_factura: '',
      numero_control: '',
      almacen: null,
      empleado: '',
      puesto: null,
      fecha_vencimiento: null,
      productos: [],
      sub_total: 0,
      total: 0,
      impuesto: 0,
      descuento: 0,
      iva: 0,
      estado: 89, 
      observacion: ''
    }
    const itemAddInit = {
      id: -1,
      cantidad: '0',
      costo_unidad: '0',
      costo_total: 0,
      cajas: '0',
      cajas_unidad: '0',
      descuento: '0',
      iva: '0',
      impuesto: '0',
      almacen: { id: null },
      producto: {},
    }
    const datos = ref(
      JSON.parse(
        JSON.stringify({
          ...datosInit,
          puesto: store.state.puestoSelect.id_puesto,
          id_empleado: store.state.user.id_empleado,
        }),
      ),
    )
     

    const BuscarProveedorRef = ref(null)
    const limpiar = () => {
      datos.value = JSON.parse(
        JSON.stringify({
          ...datosInit,
          puesto: store.state.puestoSelect.id_puesto,
          id_empleado: store.state.user.id_empleado,
        }),
      )
      itemAdd.value = JSON.parse(JSON.stringify(itemAddInit))
      BuscarProveedorRef.value.model = null
      form.value.resetValidation()
    }
    var editando = false
    const editarProducto = async item => {
      editando = true
      var prod = datos.value.productos.find(ele => ele.producto.id == item.producto.id)
      await RefProductoDetalle.value.asignarPagoducto(prod.producto)
      itemAdd.value = JSON.parse(
        JSON.stringify({
          ...prod,
          costo_unidad: monedaValor(prod.costo_unidad),
          costo_total: monedaValor(prod.costo_total),
          impuesto: monedaValor(prod.impuesto),
        }),
      )
      datos.value.productos = datos.value.productos.filter(ele => ele.producto.id != item.producto.id)
      totalizar()
    }
    const eliminarProdcuto = item => {
      datos.value.productos = datos.value.productos.filter(ele => ele.producto.id != item.producto.id)
      totalizar()
    }

    const contador = ref(-5000)
    const constadorNew = () => {
      contador.value += 1
      return contador.value
    }
    const itemAdd = ref(JSON.parse(JSON.stringify(itemAddInit)))
    const cargandos = ref(false)
    const GetProductoDetalle = item => {
      console.log('GetProductoDetalle', item)
      if (editando == false) {
        if (item != undefined) {
          itemAdd.value = {
            id: constadorNew(),
            cantidad: '0',
            costo_unidad: item.costo,
            costo_total: 0,
            cajas: '0',
            cajas_unidad: '0',
            descuento: '0',
            iva: item.impuesto_tasa.valor.toString(),
            impuesto: '0',
            almacen: { id: datos.value.almacen },
            producto: {
              id: item.id,
              nombre: item.nombre,
              costo: item.costo,
              unidad_medida: item.unidad_medida,
              ult_compra: item.ult_compra,
              cuenta_contable: item.cuenta_contable,
              ind_inventario: item.ind_inventario,
              id_producto_tipo: item.id_producto_tipo,
              
            },
          }
        }
      } else {
        editando = false
      }
    }

    onBeforeMount(() => {
      consultarMonedas()
    })
    const CargarEditar = item => {
      datos.value = { ...item, almacen: item.almacen.id }
      BuscarProveedorRef.value.asignarProveedor(item.proveedor)
      totalizar()
      goTo(0)
    }
    const validarForm = () => {
      let val = form.value?.validate()

      return val
    }

    const validarFormDetalle = () => {
      let val = formDetalle.value?.validate()

      return val
    }

    const totalizar = () => {
      datos.value.sub_total = 0
      datos.value.impuesto = 0
      datos.value.iva = 0
      datos.value.descuento = 0
      datos.value.total = 0
      datos.value.productos.forEach(element => {
        datos.value.sub_total += Number(element.cantidad) * Number(element.costo_unidad)
        datos.value.impuesto += Number(element.cantidad) * Number(element.impuesto)

        datos.value.iva += Number(
          (element.iva / 100) *
            (element.cantidad * (Number(element.costo_unidad) + Number(element.impuesto)) -
              (element.descuento / 100) *
                (element.cantidad * (Number(element.costo_unidad) + Number(element.impuesto)))),
        )

        datos.value.descuento += Number((element.descuento / 100) * (element.cantidad * (Number(element.costo_unidad) + Number(element.impuesto))))  
 
        datos.value.total += Number(element.costo_total)
      })
    }

    const agregarDetalle = () => {
      if (validarFormDetalle()) {
        console.log(
          'agregarDetalle',
          datos.value.productos.find(item => item.producto.id == itemAdd.value.producto.id),
        )
        if (datos.value.productos.find(item => item.producto.id == itemAdd.value.producto.id) == undefined) {
          datos.value.productos.push({
            ...itemAdd.value,
            costo_unidad: monedaValorReal(itemAdd.value.costo_unidad),
            costo_total: monedaValorReal(itemAdd.value.costo_total),
            impuesto: monedaValorReal(itemAdd.value.impuesto),
          })
          itemAdd.value = JSON.parse(JSON.stringify(itemAddInit))
          RefProductoDetalle.value.model = null
          formDetalle.value?.resetValidation()
          totalizar()
        } else {
          store.commit('setAlert', {
            message: 'Ya existe el producto en la factura',
            type: 'warning',
          })
        }
      }
    }

    const ActualizarLista = item => {
      console.log(item)
      context.emit('GetActualizarLista', { ...item })
    }

    const changeCalcular = tipo => {
      switch (tipo) {
        case 'cantidad':
          itemAdd.value.costo_total =
            (Number(itemAdd.value.costo_unidad) + Number(itemAdd.value.impuesto)) * Number(itemAdd.value.cantidad)
          if (itemAdd.value.descuento / 100 > 0) {
            itemAdd.value.costo_total =
              itemAdd.value.costo_total -
              Number(((itemAdd.value.descuento / 100) * itemAdd.value.costo_total).toFixed(2))
          }
          if (itemAdd.value.iva / 100 > 0) {
            itemAdd.value.costo_total = ((1 + itemAdd.value.iva / 100) * itemAdd.value.costo_total).toFixed(2)
          }
          break

        case 'costo':
          itemAdd.value.costo_total =
            (Number(itemAdd.value.costo_unidad) + Number(itemAdd.value.impuesto)) * Number(itemAdd.value.cantidad)
          if (itemAdd.value.descuento / 100 > 0) {
            itemAdd.value.costo_total =
              itemAdd.value.costo_total -
              Number(((itemAdd.value.descuento / 100) * itemAdd.value.costo_total).toFixed(2))
          }
          if (itemAdd.value.iva / 100 > 0) {
            itemAdd.value.costo_total = ((1 + itemAdd.value.iva / 100) * itemAdd.value.costo_total).toFixed(2)
          }

          break

        case 'impuesto':
          itemAdd.value.costo_total =
            (Number(itemAdd.value.costo_unidad) + Number(itemAdd.value.impuesto)) * Number(itemAdd.value.cantidad)
          if (itemAdd.value.descuento / 100 > 0) {
            itemAdd.value.costo_total =
              itemAdd.value.costo_total -
              Number(((itemAdd.value.descuento / 100) * itemAdd.value.costo_total).toFixed(2))
          }
          if (itemAdd.value.iva / 100 > 0) {
            itemAdd.value.costo_total = ((1 + itemAdd.value.iva / 100) * itemAdd.value.costo_total).toFixed(2)
          }
          break
        case 'descuento':
          itemAdd.value.costo_total =
            (Number(itemAdd.value.costo_unidad) + Number(itemAdd.value.impuesto)) * Number(itemAdd.value.cantidad)
          if (itemAdd.value.descuento / 100 > 0) {
            itemAdd.value.costo_total =
              itemAdd.value.costo_total -
              Number(((itemAdd.value.descuento / 100) * itemAdd.value.costo_total).toFixed(2))
          }
          if (itemAdd.value.iva / 100 > 0) {
            itemAdd.value.costo_total = ((1 + itemAdd.value.iva / 100) * itemAdd.value.costo_total).toFixed(2)
          }
          break

        case 'iva':
          itemAdd.value.costo_total =
            (Number(itemAdd.value.costo_unidad) + Number(itemAdd.value.impuesto)) * Number(itemAdd.value.cantidad)
          if (itemAdd.value.descuento / 100 > 0) {
            itemAdd.value.costo_total =
              itemAdd.value.costo_total -
              Number(((itemAdd.value.descuento / 100) * itemAdd.value.costo_total).toFixed(2))
          }
          if (itemAdd.value.iva / 100 > 0) {
            itemAdd.value.costo_total = ((1 + itemAdd.value.iva / 100) * itemAdd.value.costo_total).toFixed(2)
          }
          break
        case 'costo_total':
          var baseimponible = itemAdd.value.costo_total / (1 + itemAdd.value.iva / 100)
          console.log('baseimponible', baseimponible)
          var BaseDescuento = baseimponible / (1 - itemAdd.value.descuento / 100)
          console.log('descuento', BaseDescuento)
          var costo = BaseDescuento / itemAdd.value.cantidad - itemAdd.value.impuesto
          itemAdd.value.costo_unidad = costo
          break

        default:
          break
      }
    }
    const GetProveedor = item => {
      console.log('GetProveedor', item)
      datos.value.proveedor = item
    }
  

    const Guardar = () => {
      guardando.value = true
      if (validarForm()) {
        CompraServices.ordenCompraActualizar({
          ...datos.value,
          id_puesto: store.state.puestoSelect.id_puesto, 
        })
          .then(response => {
            console.log(response)
            if (response.data.mensaje == 'GUARDADA CON EXITO') {
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'success',
              })
              ActualizarLista(response.data.datos)
              limpiar()
            } else {
              store.commit('setAlert', {
                message: `${response.data.mensaje}`,
                type: 'warning',
              })
            }
          })
          .catch(error => {
            console.log(error)
            store.commit('setAlert', {
              message: error,
              type: 'error',
                  error: {
                        ...error, 
                        response: error?.response?.data
                    },
                  funcion: 'Guardar',
            })
          })
          .finally(() => {
            guardando.value = false
          })
        try {
        } catch (error) {
          store.commit('setAlert', {
            message: error,
            type: 'error',
                  error: error,
                  funcion: 'Guardar',
          })
        }
      } else {
        store.commit('setAlert', {
          message: 'Verifique que todos los datos estén completos',
          type: 'warning',
        })
      }
    }

    return {
      reglas,
      datos,
      CargarEditar,
      cargandos,
      limpiar,
      Guardar,
      form,
      ActualizarLista,
      BuscarProveedorRef,
      store,
      GetProductoDetalle,
      RefProductoDetalle,
      itemAdd,
      changeCalcular,
      formDetalle,
      agregarDetalle,
      icons: {
        mdiInformation,
        mdiDelete,
        mdiPencil,
        mdiArrowUpThick,
        mdiEqual,
        mdiArrowDownThick,
        mdiCached,
      },
      eliminarProdcuto,
      editarProducto,
      GetProveedor,
      funciones,
      validarAcciones,
      monedas,
      config,
      monedaSimbolo,
      monedaValor,
      monedaValorReal,
    }
  },
}
</script>